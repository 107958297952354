// src/blogConfig.js
import FirstBlogPost from "./blog1";
import SecondBlogPost from "./blog2";
import blog1Thumbnail from "../../assets/blog1-thumbnail.png";
import blog2Thumbnail from "../../assets/blog2-thumbnail.png";
import blog3Thumbnail from "../../assets/blog3-thumbnail.png";
import ThirdBlogPost from "./blog3";

const blogPosts = [
  {
    id: 3,
    title: "Levels of Software Automation; An AI Lens",
    date: "27 Aug 2024",
    thumbnail: blog3Thumbnail,
    description:
      "Explore the evolving landscape of software automation, from basic detection to full autonomy, and understand the critical role of AI in shaping the future of technology.",
    slug: "levels-of-ai-software-automation",
    component: <ThirdBlogPost />,
  },
  {
    id: 2,
    title:
      "Software Development Workflow: A Guide to Efficiency & Productivity",
    date: "25 March 2024",
    thumbnail: blog2Thumbnail,
    description:
      "Discover how to optimize software development workflows for greater efficiency. Learn about reducing time in development, unit testing, CI/CD, and code improvement through AI and automation, with practical examples and insights for enhancing productivity.",
    slug: "software-development-efficiency-guide",
    component: <FirstBlogPost />,
  },
  {
    id: 1,
    title:
      "5 Real World Examples: Poor Software Testing, Cost Companies Millions",
    date: "11 March 2024",
    thumbnail: blog1Thumbnail,
    description:
      "Discover the critical importance of software testing with five real-world examples where oversight led to multimillion-dollar losses. Learn how robust testing protocols are essential for protecting financial stability, brand reputation, and customer trust.",
    slug: "5-examples-of-poor-testing-cost-millions",
    component: <SecondBlogPost />,
  },
];

export default blogPosts;
