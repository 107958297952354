import React from "react";
import { Link } from "react-router-dom";
import blogPosts from "./blogs/blogPosts.js";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import "./Blog.scss";

function Blog() {
  return (
    <>
      <Navbar />
      <div className="content">
        <div className="f-row aifs jcc f-wrap blog-list">
          {blogPosts.map((post) => (
            <Link
              to={`/blog/${post.slug}`}
              key={post.id}
              className="fccc blog-preview"
            >
              <img src={post.thumbnail} alt=""></img>
              <h2>{post.title}</h2>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
