import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import "./Repositories.scss";
import { db } from "../firebase-config";
import { Link } from "react-router-dom";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import Sidebar from "./Sidebar";
import Loading from "./utils/Loading";
import LogoNavbar from "./utils/LogoNavbar";
import { useAuth } from "./utils/AuthContext";

const Repositories = () => {
  const { authUser } = useAuth();
  const [reposByOrg, setReposByOrg] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    const fetchUserRepos = (githubId) => {
      const reposQuery = query(
        collection(db, "repositories"),
        where("adminGithubId", "==", githubId)
      );
      return onSnapshot(
        reposQuery,
        (querySnapshot) => {
          const repos = [];
          querySnapshot.forEach((doc) => {
            repos.push({ id: doc.id, ...doc.data() });
          });
          try {
            const groupedRepos = groupReposByOrg(repos);
            setReposByOrg(groupedRepos);
            setShowTab(true);
            setLoading(false);
          } catch (error) {
            setFetchError("Error processing data: " + error.message);
            setLoading(false);
          }
        },
        (error) => {
          setFetchError("Error fetching data: " + error.message);
          setLoading(false);
        }
      );
    };

    const groupReposByOrg = (repositories) => {
      const grouped = repositories.reduce((acc, repo) => {
        const { orgName } = repo;
        if (!acc[orgName]) {
          acc[orgName] = [];
        }
        acc[orgName].push(repo);
        return acc;
      }, {});

      // Optionally sort repositories in each organization group
      Object.keys(grouped).forEach((org) => {
        grouped[org].sort((a, b) =>
          b.active === a.active ? 0 : a.active ? -1 : 1
        );
      });

      return grouped;
    };

    if (authUser && authUser.githubId) {
      const unsubscribe = fetchUserRepos(authUser.githubId);
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [authUser]);

  // Group the repos by organization name
  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="repos" />
      {fetchError !== "" && (
        <div className="f-row aic page-error">
          <i className="material-icons open-icn">error</i> {fetchError}
        </div>
      )}
      {loading && <Loading />}
      {showTab && (
        <div className="main-outer">
          <div className="f-col aifs main">
            <LogoNavbar />
            <div className="f-row aic jcsb header">
              <h1>Repositories</h1>
              <a
                href="https://github.com/apps/devloai/installations/select_target"
                target="_blank"
                rel="noopener noreferrer"
                className="frcc cta-btn"
              >
                <i className="material-icons">add</i> Add Repositories
              </a>
            </div>
            <p>Here is a list of repositories you have access to.</p>
            <div className="f-col main-container repo-container">
              {loading ? (
                <CircularProgress className="frcc" />
              ) : (
                <>
                  {Object.keys(reposByOrg).map((orgName) => (
                    <div key={orgName} className="f-col org">
                      <h2>{orgName}</h2> {/* Organization Name */}
                      <div className="f-row entities">
                        {reposByOrg[orgName].map((repo) => (
                          <Link
                            key={repo.githubId}
                            to={`/repo/${repo.githubId}`}
                            className="f-col jcsb entity"
                          >
                            <h3>{repo.name}</h3>
                            <div
                              className={
                                `f-row aic status-badge ` +
                                (repo.active ? "active" : "inactive")
                              }
                            >
                              {repo.active && (
                                <span className="pulsating-circle"></span>
                              )}
                              {repo.active ? "Active" : "Inactive"}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Repositories;
