import "../Dashboard.scss";

const LogoNavbar = () => {
  return (
    <div className="frcc logo-header">
      <img src="/logo512.png" alt="Logo" />
    </div>
  );
};

export default LogoNavbar;
