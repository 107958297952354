import React from "react";
import { Helmet } from "react-helmet-async";
import devloPng from "./assets/devlo.png";

const DefaultMetaTags = () => {
  return (
    <Helmet>
      <title>devlo - Your first AI teammate</title>
      <meta
        name="description"
        content="devlo is an AI-developer that automates software chores."
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="devlo - Your first AI teammate" />
      <meta
        property="og:description"
        content="devlo is an AI-developer that automates software chores, enhances productivity, and accelerates project timelines. Discover the future of coding with devlo."
      />
      <meta property="og:image" content={devloPng} />
      <meta property="og:url" content="https://devlo.ai" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content="devlo - Your first AI teammate" />
      <meta
        name="twitter:description"
        content="devlo is an AI-developer that automates software chores."
      />
      <meta name="twitter:image" content={devloPng} />
    </Helmet>
  );
};

export default DefaultMetaTags;
