import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GithubAuthProvider, signInWithPopup } from "firebase/auth";
import "./Login.scss";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { auth, db, functions } from "../firebase-config";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { fetchGitHubAuthUser, fetchGitHubUserEmails } from "./utils/githubApi";
import githubLogo from "../assets/github-mark-white.png";
import Loading from "./utils/Loading";
import { useAuth } from "./utils/AuthContext";
import mainLogo from "../assets/devlo.png";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { authUser, updateAuthUser } = useAuth();

  useEffect(() => {
    if (authUser) {
      navigate("/dashboard");
    }
  }, [navigate, authUser]);

  const handleGitHubSignIn = () => {
    setLoading(true);
    const provider = new GithubAuthProvider();
    provider.addScope("user:email");
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const credential = GithubAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        let githubUser = await fetchGitHubAuthUser(accessToken);
        if (!githubUser.email) {
          const emails = await fetchGitHubUserEmails(accessToken);
          const primaryEmail = emails.find(
            (email) => email.primary && email.verified
          );
          githubUser.email = primaryEmail ? primaryEmail.email : null;
        }

        const displayName =
          user.displayName || githubUser.name || githubUser.login;
        updateAuthUser({
          name: displayName,
          avatarUrl: githubUser.avatar_url,
          githubId: githubUser.id,
        });

        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const newUser = !userDocSnap.exists();

        let commonFields = {
          username: githubUser.login || user.displayName,
          email: githubUser.email || user.email,
          lastLogin: new Date(),
          name: displayName,
          avatarUrl: githubUser.avatar_url,
          githubId: githubUser.id,
        };
        if (newUser) {
          commonFields = {
            ...commonFields,
            createdAt: new Date(), // Set the creation date only for new documents
          };
        }
        await setDoc(userDocRef, commonFields, { merge: true });

        if (newUser) {
          const updateUserAssociations = httpsCallable(
            functions,
            "updateUserAssociations"
          );
          updateUserAssociations({
            githubId: githubUser.id,
          });
          setLoading(false);
          window.location.href =
            "https://github.com/apps/devloai/installations/select_target";
        } else {
          setLoading(false);
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <Navbar />
      <div className="frcc content">
        {loading ? (
          <Loading />
        ) : (
          <div className="fccc login-container">
            <img src={mainLogo} alt="Logo" className="logo" />
            <h3>Welcome to devlo!</h3>
            <button className="frcc github-btn" onClick={handleGitHubSignIn}>
              <img src={githubLogo} alt="github"></img> Login with GitHub
            </button>
            <p>
              By continuing, you agree to devlo's&nbsp;
              <a href="/tos">Terms of Service.</a>
            </p>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Login;
