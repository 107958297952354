import React from "react";
import "./Footer.scss";
import xLogo from "../assets/x-logo.png";
import inLogo from "../assets/linkedin.png";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="fccc footer">
      <div className="frcc links">
        <div className="f-col aifs footer-links-container">
          <NavLink to="/blog">Blog</NavLink>
          <HashLink smooth to="/faq">
            FAQ
          </HashLink>
          <NavLink to="/login">Get started</NavLink>
        </div>
        <div className="f-col aifs footer-links-container">
          <NavLink to="/contact-us">Contact us</NavLink>
          <NavLink to="/privacypolicy">Privacy Policy</NavLink>
          <NavLink to="/tos">Terms of Service</NavLink>
        </div>
      </div>
      <div className="frcc btm">
        <div className="cr">devlo © 2024.</div>
        <div className="divider" />
        <div className="frcc socials">
          <Link to="https://twitter.com/devloai" className="frcc">
            <img src={xLogo} alt="x logo" className="social-icon" />
          </Link>
          <Link to="https://linkedin.com/company/devlo-ai" className="frcc">
            <img src={inLogo} alt="linkedin logo" className="social-icon lin" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
