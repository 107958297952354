import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db, functions } from "../firebase-config";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import "../components/Dashboard.scss";
import "./OrganizationDetail.scss";
import { fetchGitHubUser } from "../components/utils/githubApi";
import Loading from "../components/utils/Loading";
import LogoNavbar from "../components/utils/LogoNavbar";
import { httpsCallable } from "firebase/functions";

const OrganizationDetail = () => {
  const { orgId } = useParams();
  const [org, setOrg] = useState(null);
  const [seats, setSeats] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [fetchError, setFetchError] = useState("");
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    const fetchOrganizationAndSeats = async () => {
      try {
        const orgRef = doc(db, "organizations", orgId);
        const orgDoc = await getDoc(orgRef);

        if (orgDoc.exists()) {
          setOrg(orgDoc.data());

          const seatsRef = collection(db, `organizations/${orgId}/seats`);
          const seatsSnapshot = await getDocs(seatsRef);
          const seatsData = seatsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setSeats(seatsData);
          setShowTab(true);
        } else {
          setFetchError("Organization not found");
        }
      } catch (error) {
        setFetchError("Error fetching organization details");
      }
      setLoading(false);
    };

    fetchOrganizationAndSeats();
  }, [orgId]);

  const handleAddUser = useCallback(async () => {
    setError(""); // Reset error message
    if (!username) {
      setError("Username is required!");
      return;
    }

    try {
      const userDetails = await fetchGitHubUser(username);
      if (!userDetails.id) {
        setError(
          "User not found. Please check the GitHub username and try again."
        );
        return;
      }

      // Check if the GitHub ID already exists in the seats
      const existingUser = seats.find(
        (seat) => seat.userGithubId === userDetails.id
      );
      if (existingUser) {
        setError("User already exists.");
        return;
      }

      // Call Firebase Function
      const addSeat = httpsCallable(functions, "addSeat");
      const result = await addSeat({
        orgId,
        userGithubId: userDetails.id,
        userDetails,
      });
      console.log(result);

      const newUser = {
        userGithubId: userDetails.id,
        username: userDetails.login,
        htmlUrl: userDetails.html_url,
        avatarUrl: userDetails.avatar_url,
        lastUpdated: new Date(),
      };
      setSeats([...seats, newUser]); // Add new user details to the list
      setUsername(""); // Clear the input field
    } catch (error) {
      // Extract the suffix of the error code if it contains a prefix
      const errorCode = error.code?.split("/")[1] || error.code;
      switch (errorCode) {
        case "resource-exhausted":
          setError("The maximum number of seats has been reached.");
          break;
        case "already-exists":
          setError("This user already exists in your seats.");
          break;
        case "unauthenticated":
          setError("You must be logged in to perform this action.");
          break;
        default:
          setError("An unexpected error occurred: " + error.message);
      }
    }
  }, [orgId, seats, username]);

  const removeSeat = async (seatId) => {
    if (window.confirm("Are you sure you want to remove this seat?")) {
      try {
        await deleteDoc(doc(db, `organizations/${orgId}/seats`, seatId));
        setSeats((seats) =>
          seats.filter((seat) => seat.userGithubId.toString() !== seatId)
        );
      } catch (error) {
        console.error("Failed to remove seat:", error);
      }
    }
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter" && username.trim()) {
      handleAddUser();
    }
  };

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="orgs" />
      {fetchError !== "" && (
        <div className="f-row aic page-error">
          <i className="material-icons open-icn">error</i> {fetchError}
        </div>
      )}
      {loading && <Loading />}
      {showTab && (
        <div className="main-outer">
          <div className="f-col aifs main org-deets">
            <LogoNavbar />
            <div className="frcc route">
              <Link to="/orgs">Organizations</Link>
              <i className="material-icons icn">navigate_next</i>
              {org && <span>{org.name}</span>}
            </div>
            <div className="f-row aic jcsb header">
              <div className="frcc lefty">
                <a
                  href={org.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="frcc title"
                >
                  <h1 className="f-row aifs">
                    {org.name}
                    <i className="material-icons open-icn">open_in_new</i>
                  </h1>
                </a>
                <div
                  className={`frcc status-badge ${
                    org.active ? "active" : "inactive"
                  }`}
                >
                  {org.active && <span className="pulsating-circle"></span>}
                  {org.active ? "Active" : "Inactive"}
                </div>
              </div>
              <a
                href={`https://github.com/settings/installations/${org.installationId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn"
              >
                Modify Installation
              </a>
            </div>
            <div className="f-col info-container">
              <p>
                You have used {seats.length} seat
                {seats.length > 1 && "s"}.
              </p>
              <div>
                <h4>Add more users</h4>
                <div className="f-row aic add-user">
                  <input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter GitHub Username"
                    type="text"
                    onKeyDown={onEnterPress}
                  />
                  <button onClick={handleAddUser} disabled={!username.trim()}>
                    Add User
                  </button>
                  {error && (
                    <p className="f-row aic error">
                      <i className="material-icons open-icn">error</i> {error}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="seats-section">
              <h2>Seats</h2>
              <table>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Modify</th>
                  </tr>
                </thead>
                <tbody>
                  {seats.map((seat) => (
                    <tr key={seat.userGithubId}>
                      <td className="f-row aic username">
                        <a href={seat.htmlUrl} className="f-row aic username">
                          <img
                            src={seat.avatarUrl}
                            alt="user avatar"
                            className="user-avatar"
                          />
                          <span>
                            <span className="underline">{seat.username}</span>
                          </span>
                        </a>
                      </td>
                      <td>
                        <div
                          className="f-row remove-btn"
                          tabIndex={0}
                          onClick={() =>
                            removeSeat(seat.userGithubId.toString())
                          }
                        >
                          Remove
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizationDetail;
