import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Import Helmet for setting the document head
import Navbar from "../components/Navbar";

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>404 Not Found - Your Site Name</title>
      </Helmet>
      <Navbar />
      <div
        className="fccc"
        style={{ backgroundColor: "black", color: "white", height: "100%" }}
      >
        <h1>404 - Page Not Found</h1>
        <p>We're sorry, the page you requested could not be found.</p>
        <Link to="/">Go Home</Link>
      </div>
    </>
  );
};

export default NotFoundPage;
