// RepositoryDetail.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import "../components/Dashboard.scss";
import LogoNavbar from "../components/utils/LogoNavbar";
import Loading from "../components/utils/Loading";

const RepositoryDetail = () => {
  const { repoId } = useParams();
  const [repository, setRepository] = useState(null);
  const [pulls, setPulls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    const fetchRepository = async () => {
      try {
        const repoRef = doc(db, "repositories", repoId);
        const repoDoc = await getDoc(repoRef);
        if (repoDoc.exists()) {
          setRepository(repoDoc.data());

          const pullsRef = collection(db, `repositories/${repoId}/pulls`);
          const querySnapshot = await getDocs(pullsRef);
          const pullData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPulls(pullData);
          setShowTab(true);
        } else {
          setFetchError("Repository not found");
        }
      } catch (error) {
        console.log(error);
        setFetchError("Error fetching repository details");
      }
      setLoading(false);
    };

    fetchRepository();
  }, [repoId]);

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="repos" />
      {fetchError !== "" && (
        <div className="f-row aic page-error">
          <i className="material-icons open-icn">error</i> {fetchError}
        </div>
      )}
      {loading && <Loading />}
      {showTab && (
        <div className="main-outer">
          <div className="f-col aifs main repo-deets">
            <LogoNavbar />
            <div className="frcc route">
              <Link to="/repos">Repositories</Link>
              <i className="material-icons icn">navigate_next</i>{" "}
              {repository.full_name}
            </div>
            <div className="f-row aic jcsb header">
              <div className="frcc lefty">
                <a
                  href={repository.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="frcc title"
                >
                  <h1 className="f-row aifs">
                    {repository.full_name}{" "}
                    <i className="material-icons open-icn">open_in_new</i>
                  </h1>
                </a>
                <div
                  className={
                    `f-row aic status-badge ` +
                    (repository.active ? "active" : "inactive")
                  }
                >
                  {repository.active && (
                    <span className="pulsating-circle"></span>
                  )}
                  {repository.active ? "Active" : "Inactive"}
                </div>
              </div>
              <a
                href={`https://github.com/settings/installations/${repository.installationId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn"
              >
                Modify Installation
              </a>
            </div>
            <div className="f-row directive">
              {repository.active ? (
                <p>
                  devlo is active on pull requests for this repository. <br />
                  You can ask questions or request code changes by starting your
                  comments with <span className="code">@devloai</span>
                </p>
              ) : (
                <p>devlo will not perform any actions on this pull request</p>
              )}
            </div>
            <div className="f-col runs">
              <h2>Pull requests</h2>
              <table>
                <thead>
                  <tr>
                    <th>Pull #</th>
                    <th>Title</th>
                    <th>User</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {pulls.map((pull) => (
                    <tr key={pull.id}>
                      <td>
                        <a href={pull.html_url}>{pull.id}</a>
                      </td>
                      <td>
                        <a href={pull.html_url}>{pull.title}</a>
                      </td>
                      <td>{pull.userLogin}</td>
                      <td>{pull.state}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RepositoryDetail;
