import { useEffect } from "react";
import { useAuth } from "./utils/AuthContext";

const Logout = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return null;
};

export default Logout;
