import React from "react";
import { useParams } from "react-router-dom";
import blogPosts from "./blogPosts";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet-async";
import "./blogPost.scss";

function BlogPost() {
  const { slug } = useParams();
  const post = blogPosts.find((p) => p.slug === slug);

  return (
    <>
      {post && (
        <Helmet>
          <title>{post.title}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={post.description} />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.description} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={post.thumbnail} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={post.title} />
          <meta name="twitter:description" content={post.description} />
          <meta name="twitter:image" content={post.thumbnail} />
        </Helmet>
      )}
      <Navbar />
      <div className="content">
        <div className="f-col jcc blog">
          {post ? (
            <>
              <h1>{post.title}</h1>
              <p className="date">{post.date} / Blog</p>
              {post.component}
            </>
          ) : (
            <p>Blog post not found.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BlogPost;
