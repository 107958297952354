export const fetchGitHubUser = async (username) => {
  return fetch(`https://api.github.com/users/${username}`, {
    headers: {
      Accept: "application/vnd.github.v3+json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error fetching user:", error);
      throw error; // Rethrow to handle errors in the component
    });
};

export const fetchGitHubAuthUser = async (accessToken) => {
  return fetch("https://api.github.com/user", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/vnd.github.v3+json",
    },
  }).then((response) => response.json());
};

export const fetchGitHubUserEmails = async (accessToken) => {
  return fetch("https://api.github.com/user/emails", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/vnd.github.v3+json",
    },
  }).then((response) => response.json());
};
