import React, { useState, useEffect } from "react";
import { db } from "../firebase-config";
import "./Dashboard.scss";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Loading from "./utils/Loading";
import LogoNavbar from "./utils/LogoNavbar";
import { useAuth } from "./utils/AuthContext";

const Organizations = () => {
  const { authUser } = useAuth();
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    if (authUser && authUser.githubId) {
      const orgsQuery = query(
        collection(db, "organizations"),
        where("adminGithubId", "==", authUser.githubId)
      );
      const unsubscribe = onSnapshot(
        orgsQuery,
        (querySnapshot) => {
          const orgs = [];
          querySnapshot.forEach((doc) => {
            orgs.push({ id: doc.id, ...doc.data() });
          });
          try {
            setOrgs(orgs);
            setShowTab(true);
            setLoading(false);
          } catch (error) {
            setFetchError("Error processing data: " + error.message);
            setLoading(false);
          }
        },
        (error) => {
          setFetchError("Error fetching data: " + error.message);
          setLoading(false);
        }
      );

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [authUser]);

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="orgs" />
      {fetchError !== "" && (
        <div className="f-row aic page-error">
          <i className="material-icons open-icn">error</i> {fetchError}
        </div>
      )}
      {loading && <Loading />}
      {showTab && (
        <div className="main-outer">
          <div className="f-col aifs main">
            <LogoNavbar />
            <div className="f-row aic jcsb header">
              <h1>Organizations</h1>
              <a
                href="https://github.com/apps/devloai/installations/select_target"
                target="_blank"
                rel="noopener noreferrer"
                className="frcc cta-btn"
              >
                <i className="material-icons">add</i> Add organizations
              </a>
            </div>
            <p>Here is a list of organizations you have access to.</p>
            <div className="f-col main-container org-container">
              {loading ? (
                <CircularProgress className="frcc" />
              ) : (
                <div className="f-row entities">
                  {orgs.map((org) => (
                    <Link
                      key={org.id}
                      to={`/org/${org.githubId}`}
                      className="f-col jcsb entity"
                    >
                      <img src={org.avatarUrl} alt="github org avatar"></img>
                      <h3 className="f-row aic name">
                        <i className="material-icons">
                          {org.type === "User" ? "person" : "corporate_fare"}
                        </i>
                        {org.name}
                      </h3>
                      <div
                        className={
                          `f-row aic status-badge ` +
                          (org.active ? "active" : "inactive")
                        }
                      >
                        {org.active && (
                          <span className="pulsating-circle"></span>
                        )}
                        {org.active ? "Active" : "Inactive"}
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Organizations;
