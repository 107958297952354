import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom"; // assuming you are using react-router

const CanonicalLink = () => {
  const location = useLocation(); // This hook gives you the current URL path
  const baseUrl = "https://devlo.ai";

  const fullUrl = `${baseUrl}${location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={fullUrl} />
    </Helmet>
  );
};

export default CanonicalLink;
