import React, { useState, useCallback, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./ContactUs.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@mui/material";
import { functions } from "../firebase-config";
import { httpsCallable } from "firebase/functions";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    orgName: "",
    orgWebsite: "",
    reason: "",
    message: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onCaptchaChange = useCallback((value) => {
    setFormData((formData) => ({ ...formData, recaptchaToken: value }));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    // Execute reCAPTCHA directly in the submit handler
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LeuWsYpAAAAADoFEM3-GRfs-8cKcbzRMTEJ6ygs", {
          action: "submit",
        })
        .then((token) => {
          submitForm(token); // Proceed to submit the form with the token
        });
    });
  };

  const submitForm = async (recaptchaToken) => {
    if (!recaptchaToken) {
      alert("Please confirm you're not a robot.");
      setSubmitting(false);
      return;
    }

    const formDataWithToken = { ...formData, recaptchaToken };

    try {
      const submitContactFormFunction = httpsCallable(
        functions,
        "submitContactUsForm"
      );
      const result = await submitContactFormFunction(formDataWithToken);
      const data = result.data;
      if (data.error) {
        alert(data.message || "Failed to submit form");
      }

      if (data.message === "Captcha verification failed") {
        alert("Captcha verification failed. Please try again.");
      } else {
        setShowSuccessMessage(true);
      }
    } catch (error) {
      alert(`An error occurred while submitting the form: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };
  // This will programmatically execute reCAPTCHA when the component loads
  useEffect(() => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LeuWsYpAAAAADoFEM3-GRfs-8cKcbzRMTEJ6ygs", {
            action: "submit",
          })
          .then(onCaptchaChange);
      });
    }
  }, [onCaptchaChange]);

  return (
    <>
      <Navbar />
      <div className="content">
        <div className="contact-us-container">
          <h1>Contact Us</h1>
          {showSuccessMessage ? (
            <div className="fccc success-message">
              <span className="material-icons">check_circle</span>
              Your response has been submitted.
              <br /> We will be in touch shortly.
            </div>
          ) : (
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your full name"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="name@company.com"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="orgName">Organization Name</label>
                <input
                  type="text"
                  id="orgName"
                  name="orgName"
                  placeholder="Your organization name (optional)"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="orgWebsite">Organization Website</label>
                <input
                  type="text"
                  id="orgWebsite"
                  name="orgWebsite"
                  placeholder="https://your-organization.com"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="reason">Reason for Contacting Us</label>
                <select id="reason" name="reason" onChange={handleChange}>
                  <option value="">-- Please choose an option --</option>
                  <option value="productInterest">Interest in Product</option>
                  <option value="support">Support Request</option>
                  <option value="general">General Inquiry</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  placeholder="How can we help you?"
                  onChange={handleChange}
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={submitting}
                className="submit-btn"
              >
                {submitting ? (
                  <CircularProgress size={16} style={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </button>
              <p className="captcha-msg">
                This site is protected by reCAPTCHA and the Google&nbsp;
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                &nbsp;and&nbsp;
                <a href="https://policies.google.com/terms">Terms of Service</a>
                &nbsp;apply.
              </p>
              <ReCAPTCHA
                sitekey="6LeuWsYpAAAAADoFEM3-GRfs-8cKcbzRMTEJ6ygs"
                onChange={onCaptchaChange}
                size="invisible"
                className="fccc"
                badge="inline" // can be 'inline' or 'bottomright' or 'bottomleft'
              />
            </form>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
