import { CircularProgress } from "@mui/material";
import "./Loading.scss";

const Loading = () => {
  return (
    <div className="fccc loading">
      <div className="progress">
        <CircularProgress style={{ width: 50, height: 50 }} />
        <div className="logo">
          <img src="/logo512.png" alt="Logo" />
        </div>
      </div>
      <p>Loading</p>
    </div>
  );
};

export default Loading;
